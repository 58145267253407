//import { roboto } from "@theme-ui/presets";
//Theme based off roboto preset (it is included in the theme-ui plugin config)
import buttons from "./buttons";
import colors from "./colors";
import fontWeights from "./font-weights";
import forms from "./forms";
import layouts from "./layouts";
import nav from "./nav";
import overlays from "./overlays";
import radii from "./radii";
import text from "./text";
import { fonts, styles } from "./typography";

export default {
  colors: { ...colors },
  fontWeights: { ...fontWeights },
  layout: { ...layouts },
  fonts: { ...fonts },
  styles: { ...styles },
  overlays: { ...overlays },
  buttons: { ...buttons },
  radii: { ...radii },
  text: { ...text },
  nav: { ...nav },
  forms: { ...forms },
};
