const forms = {
  validationError: { color: "primary" },
  label: {
    fontSize: 1,
    fontWeight: "bold",
    color: "text",
    width: "auto",
  },
  input: {
    borderColor: "gray",
    color: "text",
    fontFamily: "body",
    "&:focus": {
      borderColor: "primary",
      borderWidth: "1px",
      //boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
      outline: "none",
      bg: "white",
      color: "text",
      fontFamily: "body",
    },
  },
  select: {
    borderColor: "gray",
    "&:focus": {
      borderColor: "primary",
      boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
      outline: "none",
    },
  },
  textarea: {
    borderColor: "gray",
    color: "text",
    fontFamily: "body",
    "&:focus": {
      borderColor: "primary",
      //boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
      outline: "none",
      bg: "white",
      color: "text",
      fontFamily: "body",
    },
  },
  slider: {
    bg: "muted",
  },
};

export default forms;
