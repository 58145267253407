const overlayDefauls = {
  position: "absolute",
  width: "100%",
  height: "100%",
  left: 0,
  top: 0,
};

const overlays = {
  primaryGradient: {
    ...overlayDefauls,
    backgroundImage: (t) =>
      `linear-gradient(270deg, rgba(210, 21, 46, 0.9) 55.32%, rgba(204, 45, 66, 0.36) 100%)`,
  },
};

export default overlays;
