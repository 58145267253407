import { Element, scroller } from "react-scroll";

export const isExternalLink = (href: string): boolean => {
  return /(http(s)?):\/\/*/.test(href);
};

type Link = {
  externalLink: string;
  linkToPage: Queries.ContentfulPage | null;
};
export type Href = Link | Queries.ContentfulNavItem;
const isLink = (b: Href): b is Link => {
  return (b as Link).externalLink !== undefined;
};

export const cleanInternalLink = (href: string) => {
  return href.substring(0, 1) === "/" ? href : "/" + href;
};

export const getLinkHref = (link: Href): string => {
  if (isLink(link)) {
    return link.externalLink
      ? link.externalLink
      : cleanInternalLink(link.linkToPage?.slug || "") || "/";
  } else {
    const l = link.page ? `/${link.page.slug}` || "" : "";
    return `${l}${link.anchor ? link.anchor : ""}`;
  }
};

export const anchorScroll = (location: Location | string) => {
  //console.log("in anchorscroll");

  let hash ="";
  if (typeof location === "string"){
    hash = location.replace("#", "")
  }else{
    hash = location.hash.replace("#", "");
  }


  if (location && hash) {
    console.log("in anchorscroll bb");
    setTimeout(() => {
      scroller.scrollTo(hash, {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: -150, // Scrolls to element + 0 pixels down the page
      });
    }, 0);
  }
};
