import * as React from "react";
import type { GatsbyBrowser } from "gatsby";
import "./src/styles/styles.css";

import "@fontsource/italiana"; // Defaults to weight 400 with all styles included.
import "@fontsource/montserrat";
import "@fontsource/satisfy";

import { anchorScroll } from "./src/components/navigation/nav-helpers";

export const onRouteUpdate: GatsbyBrowser["onPreRouteUpdate"] = async ({
  location,
  prevLocation,
}) => {
  console.log("onRouteUpdate");
  anchorScroll(location);
  return true;
};

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  routerProps: { location },
}) => {
  console.log("shouldUpdateScroll");
  anchorScroll(location);
  return true;
};


