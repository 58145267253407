import { roboto } from "@theme-ui/presets";
import "@fontsource/satisfy";
import "@fontsource/source-sans-pro";

export const fonts = {
  body: "Source Sans Pro, Montserrat, Roboto, system-ui, sans-serif",
  heading: "Source Sans Pro, Italiana,Montserrat,Roboto, system-ui, sans-serif",
  headingScript:
    "Satisfy, Source Sans Pro, Italiana,Montserrat,Roboto, system-ui, sans-serif",
  monospace: "'Roboto Mono'",
  script: "Italiana, Roboto, system-ui, sans-serif",
};

export const styles = {
  ...roboto.styles,
  a: {
    color: "primary",
  },
  h1: {
    ...roboto.styles.h1,
    //fontSize: [5],  // this is how you adjust font sizes etc
  },
  h2: {
    ...roboto.styles.h2,
    fontFamily: fonts.headingScript,
    color: "primary",
    fontWeight: 400,
  },
  h3: {
    ...roboto.styles.h3,
  },
  h4: {
    ...roboto.styles.h4,
  },
  h5: {
    ...roboto.styles.h5,
  },
};
