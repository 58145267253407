const text = {
  title: {
    color: "text",
    textTransform: "uppercase",
    fontSize: [4, 6],
    letterSpacing: [".5em"],
    fontWeight: 400,
    small: {
      color: "text",
      textTransform: "uppercase",
      fontSize: [4],
      letterSpacing: [".5em"],
      fontWeight: 400,
    },
  },
  heading: {
    color: "primary",
    fontFamily: "headingScript",
    fontSize: [5],
    letterSpacing: [".1em"],
    fontWeight: 400,
    plain: {
      pt: 4,
      pb: 3,
      color: "primary",
      fontFamily: "heading",
      fontSize: [4],
      letterSpacing: [".1em"],
      fontWeight: 400,
      textAlign: ["center"],
    },
  },

  subtitle: {
    color: "primary",
    //textTransform: "uppercase",
    fontSize: [3, 4],
    letterSpacing: [".1em"],
    fontWeight: 400,
  },
};

export default text;
